/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import _ from 'underscore';
import SelectField from './SelectField';
import Label from './Label';
import ValidationError from './ValidationError';
import Validators from './Validators';
import styles from './FormSelect.module.scss';

function arrowRenderer() {
    return (
        <span>+</span>
    );
}

interface Props {
    labelwidth: string,
    inputwidth: string,
    label: string,
    name: string,
    multi: boolean,
    clearable: boolean,
    arrowRenderer: unknown,
    validationError: string,
    onChange: (value: string, name: string, validated: boolean) => void,
    validators: string[],
    options: any,
    value?: any,
    multiValues: boolean
}

interface State {
    validationError?: string
}

class FormSelect extends Component<Props, State> {
    static propTypes = {
        labelwidth: PropTypes.string,
        inputwidth: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        multi: PropTypes.bool,
        clearable: PropTypes.bool,
        arrowRenderer: PropTypes.any,
        validationError: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        validators: PropTypes.array,
        multiValues: PropTypes.bool
    };

    static defaultProps = {
        labelwidth: '8',
        inputwidth: '4',
        label: '',
        name: 'foo',
        multi: false,
        clearable: false,
        arrowRenderer: false,
        validationError: null,
        multiValues: false,
        validators: []
    };

    constructor(props:Props) {
        super(props);
        this.state = {
            validationError: this.props.validationError
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps:Props) {
        const newState: State = {};
        if (nextProps.validationError !== this.props.validationError) {
            newState.validationError = nextProps.validationError;
        }
        if (!_.isEmpty(newState)) {
            this.setState(newState);
        }
    }

    handleChange(val:unknown) {
        let value;
        if (val === null) {
            value = '';
        } else if (typeof val === 'object') {
            value = val;
        } else {
            ({ value } = val as any);
        }
        const validation = this.validate(value);
        this.setState({
            validationError: validation.validationErrors
        });
        this.props.onChange(value, this.props.name, validation.validated);
    }

    validate(value:string) {
        let validated = true;
        let validationErrors = '';
        this.props.validators.forEach(validator => {
            const validationError = (Validators as any)[validator](value);
            if (validationError) {
                validated = false;
                // validationErrors.push(validationError);
                validationErrors += ' ' + validationError;
            }
        });
        // alert(validationErrors[0]);
        return {
            validationErrors,
            validated
        };
    }

    render() {
        if (this.props.arrowRenderer) {
            // eslint-disable-next-line
            let arrowRenderer=this.props.arrowRenderer;
        } else if (this.props.multi) {
            // eslint-disable-next-line
                let arrowRenderer=function arrowRenderer () {
                return (
                    <span>+</span>
                );
            };
        }
        if (this.props.label !== '') {
            return (
                <div id={'form_' + this.props.name} className={styles.formselect}>
                    <FilterDesklet className={styles.row}>
                        <Label {...this.props} />
                        <div className={styles['col-' + this.props.inputwidth]}>
                            <SelectField {... this.props} arrowRenderer={arrowRenderer} onChange={this.handleChange} />
                            {this.state.validationError
                                && <ValidationError validationError={this.state.validationError} />
                            }
                        </div>
                    </FilterDesklet>
                </div>
            );
        }
        const fullWidth = parseInt(this.props.inputwidth, 10) + parseInt(this.props.labelwidth, 10);
        return (
            <div id={'form_' + this.props.name} className={styles.formselect}>
                <div className={styles.row}>
                    <div className={styles['col-' + fullWidth]}>
                        <SelectField {... this.props} arrowRenderer={arrowRenderer} onChange={this.handleChange} />
                        {this.props.validationError
                            && <ValidationError validationError={this.state.validationError} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const FilterDesklet = styled('div')`
    display: grid;
    grid-template-columns: 1fr 4fr;
`;

export default FormSelect;
